export function formatBytes(bytes,decimals) {
  // https://gist.github.com/zentala/1e6f72438796d74531803cc3833c039c
  if(bytes == 0) return '0 Bytes';
  var k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function prob2sigma(x) {
  if (x === 1.0) return x * 100
  let z = 0
  const lim = 4.53988e-5
  const a = 0.2
  z = x < lim ? lim : x > 1.0 ? 1.0 : x
  let zz = 50.0 - (1.0 / a) * Math.log(1.0 / z - 1.0)
  if (zz < 0.000102575) zz = 0
  if (zz > 100.0) zz = 100.0
  return zz
}

export function sigma2prob(zz) {
  const a = 0.2
  const z = 1.0 / (Math.exp((50.0 - zz) * a) + 1)
  return z
}

export function rgbhash(string) {
  // see https://gist.github.com/0x263b/2bdd90886c2036a1ad5bcf06d6e6fb37
  let hash = 0
  if (string.length === 0) return hash
  for (var i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
    hash = hash & hash
  }
  const rgb = [0, 0, 0]
  for (var i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 255
    rgb[i] = value
  }

  return rgb
}

export function debounce(f, delay = 50) {
  let timeout = null
  return function (...args) {
    if (timeout !== null) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => f.apply(this, args), delay)
  }
}

export function omitIf(data, condition) {
  const copy = {}
  for (let key in data) {
    if (!condition(data[key])) {
      copy[key] = data[key]
    }
  }
  return copy
}

export function replaceUnallowedCharsInTagName(tagName) {
  if (typeof tagName === 'string' || tagName instanceof String) {
    let cleanTagName = tagName.replaceAll(' ','_')
      .replaceAll('<', '_')
      .replaceAll('>', '_')
      .replaceAll('&', '_')
      .replaceAll('{', '_')
      .replaceAll('}', '_')
      .replaceAll(';', '_')
      .replaceAll(':', '_')
    return cleanTagName
  } else {
    return tagName
  }
}

